<template>
    <div v-if="render" class="container px-2 px-md-0 mx-auto col-12 col-md-10 col-lg-8">
        <div v-if="quoteStatus!=='OK'">
            <div class="row">
                <div class="col-12">
                    <p>Something went wrong, please try again!</p>
                </div>
            </div>
        </div>
        <div v-if="quoteStatus==='OK'">
            <div class="row">
                <div class="col-12 text-center">
                    <h4>
                        Your indicative price is
                    </h4>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="text-center">
                        <div>
                            <h1 class="quote-premium font-weight-500 text-green">
                                {{ premiumDisplay }}
                            </h1>
                        </div>
                        <div>
                            <small>including IPT and fees</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12 text-center">
                    <ul class="features-list pl-0">
                        <li class="my-1">
                            <span>Comprehensive cover</span>
                            <i class="fa fa-check ml-1 text-green" />
                        </li>
                        <li class="my-1">
                            <span>On the road in 5 minutes</span>
                            <i class="fa fa-check ml-1 text-green" />
                        </li>
                        <li class="my-1">
                            <span>Easy to extend</span>
                            <i class="fa fa-check ml-1 text-green" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12 text-center">
                    <ul class="features-list pl-0">
                        <li class="my-1">
                            <strong class="text-green font-weight-bold">PLUS</strong>
                        </li>
                        <li class="my-1">
                            <strong class="font-weight-bold">Carbon offset your trips for FREE</strong>
                        </li>
                        <li class="my-1">
                            <span>with Zixty Miles</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12 text-center mb-1">
                    <p>
                        To buy this policy tap 'Buy Now' to download the app
                    </p>
                </div>
                <div class="col-12 text-center">
                    <b-button variant="primary" href="https://app.zixty.com/go" target="_blank" class="rounded-pill opacity-button">
                        Buy Now
                    </b-button>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="mt-3">
                        <h5 class="font-weight-400">
                            Quote Details
                        </h5>
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="reg"
                            field-label="Vehicle Registration"
                            :field-value="vehicleRegDisplay"
                            :label-cols="5"
                        />
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="loc"
                            field-label="Length of Cover"
                            :field-value="lengthOfCoverDisplay"
                            :label-cols="5"
                        />
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="policy-start"
                            field-label="Policy Start"
                            :field-value="policyStartDisplay"
                            :label-cols="5"
                        />
                        <div v-for="doc in quote.quoteResponse.quote.documents" :key="doc.document_ref">
                            <div class="col-12">
                                <b-form-group
                                    id="display-group-start"
                                    :label="getDocumentName(doc)"
                                    label-for="display-policy-doc-link"
                                    label-cols="5"
                                    label-cols-md="5"
                                    label-cols-lg="4"
                                    :label-size="sizing"
                                    :state="!doc.error"
                                >
                                    <b-link
                                        id="display-policy-doc-link"
                                        target="_self"
                                        @click="downloadPdf(doc, quote.quoteResponse.quote.insurer)"
                                    >
                                        <div class="mt-2">
                                            <i class="fa fa-download" />
                                            <span class="ml-1">Download</span>
                                        </div>
                                    </b-link>
                                    <span v-if="doc.error" class="text-danger ml-3">
                                        Unfortunately, we could not download your document.
                                    </span>
                                    <span v-if="doc.downloaded" class="text-success ml-3">
                                        Downloaded
                                    </span>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row my-3">
                <div class="col-12">
                    <div class="mt-3">
                        <h5 class="font-weight-400">
                            Your Details
                        </h5>
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="name"
                            field-label="Name"
                            :field-value="nameDisplay"
                        />
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="address"
                            field-label="Address"
                            :field-value="addressDisplay"
                            split-line=", "
                        />
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="dob"
                            field-label="Date of Birth"
                            :field-value="dateOfBirthDisplay"
                        />
                        <DisplayQuoteField
                            :quote-status="quoteStatus"
                            :sizing="sizing"
                            field-name="email"
                            field-label="Email"
                            :field-value="quote.quoteRequest.email_address"
                        />
                    </div>
                </div>
            </div>
            <div class="row py-3 my-3">
                <div class="col-12 text-center mb-1">
                    <p>
                        To buy this policy tap 'Buy Now' to download the app
                    </p>
                </div>
                <div class="col-12 text-center">
                    <b-button variant="primary" href="https://app.zixty.com/go" target="_blank" class="rounded-pill opacity-button">
                        Buy Now
                    </b-button>
                </div>
            </div>
            <div class="row pt-3 my-3">
                <div class="col-12 text-center">
                    <div class="col-12 col-md-10 col-lg-8 mx-auto">
                        <p>
                            Subject to Insurer terms and conditions.
                        </p>
                        <p>
                            We make assumptions to work out your indicative price. Additional answers are required when you buy a policy through the app which may affect your final price.
                        </p>
                        <p>
                            <QuoteAssumptions action-variant="link" action-label="See Assumptions" />
                        </p>
                    </div>
                </div>
            </div>
            <div class="row mb-3 col-12 text-center">
                <div class="col-12 col-md-10 col-lg-8 mx-auto">
                    <b-button type="reset" variant="secondary" class="b-button-lighter-gray rounded-pill opacity-button" @click="resetPage($event)">
                        Restart Quote
                    </b-button>
                </div>
            </div>

            <div class="row d-md-none">
                <GetStartedNowOnMobile />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import { dispDateTime } from "@/js/DisplayUtils";
import ApiService from "@/js/ApiService";
import { guessedTimezoneName } from "@/js/DisplayUtils";
import DisplayQuoteField from "@/components/DisplayQuoteField";
import QuoteAssumptions from "@/components/QuoteAssumptions";
import GetStartedNowOnMobile from "@/components/GetStartedNowOnMobile";

export default {
    name: "QuoteResults",
    components: {
        DisplayQuoteField,
        QuoteAssumptions,
        GetStartedNowOnMobile,
    },
    props: {
        quoteInitial: {
            type: Object,
            required: true,
        },
        sizing: {
            type: String,
            required: true,
            default: "md"
        }
    },
    data() {
        return {
            quote: null,
            render: false
        };
    },
    computed: {
        quoteStatus: function() {

            if (this.quote.quoteResponse.status !== "OK") {
                return this.quote.quoteResponse.status;
            }

            const now = moment.tz(moment(), guessedTimezoneName);
            const expiry = moment.tz(moment(this.quote.quoteResponse.quote.expiry), guessedTimezoneName);
            if (now > expiry) {
                return "EXPIRED"
            }

            return "OK"
        },
        premiumDisplay: function() {
            var formatter = new Intl.NumberFormat("en-GB", {
                style: "currency",
                currency: "GBP",
            });
            return formatter.format(Number(this.quote.quoteResponse.quote.premium))
        },
        lengthOfCoverDisplay: function() {
            const hours = this.quote.quoteRequest.cover_period_hours;
            if (hours < 24) {
                const unit = hours === 1 ? "hour" : "hours";
                return `${hours} ${unit}`;
            } else {
                const days = hours / 24;
                const unit = days === 1 ? "day" : "days";
                return  `${days} ${unit}`;
            }
        },
        policyStartDisplay: function() {
            return `${dispDateTime(this.quote.quoteRequest.cover_start, true, false)}`.split(" ")[0];
        },
        nameDisplay: function() {
            return `${this.quote.quoteRequest.first_name} ${this.quote.quoteRequest.last_name}`
        },
        addressDisplay: function() {
            return `${this.quote.quoteRequest.address}, ${this.quote.quoteRequest.postcode}`
        },
        vehicleRegDisplay: function() {
            return `${this.quote.quoteRequest.registration_number}`
        },
        dateOfBirthDisplay: function() {
            const value = this.quote.quoteRequest.date_of_birth
            return `${value.substring(8, 10)}/${value.substring(5, 7)}/${value.substring(0, 4)}`
        }
    },
    mounted() {
        this.quote = this.quoteInitial;
        this.render = true;
        this.$nextTick(function() {
            setTimeout(function() {
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }, 25);
        });
    },
    methods: {
        resetPage() {
            window.location.reload();
        },
        getDocumentName(document) {
            if (document.document_type === "IPID") {
                return "Policy IPID";
            }
            if (document.document_type === "POLICY_WORDING") {
                return "Policy Wording";
            }
            return "Other Policy Document";
        },
        async downloadPdf(policyDocument, insurer) {
            let response;
            try {
                response = await ApiService.getQuoteDocument(policyDocument.url);
                if (response.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `ZIXTY_${insurer}_${policyDocument.document_type}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    this.setDocumentError(policyDocument.document_type, false);
                    this.setDocumentDownloaded(policyDocument.document_type);
                } else {
                    this.setDocumentError(policyDocument.document_type, true);
                }
            } catch (error) {
                console.log(error);
                this.$logger.error(error);
                this.setDocumentError(policyDocument.document_type, true);
            }
        },
        resetQuote() {
            this.$emit("resetQuote");
        },
        setDocumentError(documentType, isError) {
            const doc = this.quote.quoteResponse.quote.documents.filter(x=>x.document_type === documentType)[0]
            this.$set(doc, "error", isError);
        },
        setDocumentDownloaded(documentType) {
            const doc = this.quote.quoteResponse.quote.documents.filter(x=>x.document_type === documentType)[0]
            this.$set(doc, "downloaded", true);
        },
        documentTypeId(document) {
            return `input-live-feedback-${document.document_type}`
        },
        scrollToElement(refName) {
            const el = this.$refs[refName];
            if (el) {
                el.scrollIntoView({behavior: "smooth"});
            }
        }
    }
};
</script>

<style lang='scss' scoped>
.quote-premium {
    font-size: 3rem;
    line-height: 1.42857;
}
.quote-premium-pre {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.42857;
}
.quote-premium-post {
    font-size: 1.2rem;
    line-height: 1.42857;
}

.form-group {
    margin-bottom: 0.2rem !important;
}

.pdf-image {
    width: calc(0.15rem * 20);
}

.features-list {
    list-style: none;
}
</style>
