<template>
    <b-container aria-label="main">
        <div class="row h-100">
            <div class="col-12 col-md-9">
                <Header :show-buttons="quoteAvailable" />
                <QuoteResults v-if="quoteAvailable" :quote-initial="quote" :sizing="sizing" @resetQuote="resetQuote" />
                <QuoteForm v-show="!quoteAvailable" :sizing="sizing" @quoteProvided="handleQuote" />
            </div>
            <div class="d-none d-md-block col-md-3">
                <SidebarFullApp />
            </div>
        </div>
    </b-container>
</template>

<script>
import QuoteForm from "@/components/QuoteForm";
import QuoteResults from "@/components/QuoteResults";
import Header from "@/components/Header";
import SidebarFullApp from "@/components/SidebarFullApp";

export default {
    name: "Home",

    components: {
        QuoteForm,
        QuoteResults,
        Header,
        SidebarFullApp,
    },
    data() {
        return {
            // quote: require("@/js/QuoteDataMock.js").default,
            quote: {},
            sizing: "md"
        };
    },
    computed: {
        quoteAvailable: function() {
            return this.quote.hasOwnProperty("quoteResponse");
        }
    },
    created() {
        window.addEventListener("resize", this.resizeHandler);
        this.resizeHandler()
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeHandler);
    },
    mounted() {
        const isQueryPrice = (this.$route.query || {}).price || "";
        const isQueryPriceYes = isQueryPrice === "yes";
        const isQueryPriceNo = isQueryPrice === "no";

        if (this.quoteAvailable) {
            if (!isQueryPriceYes) {
                this.$router.push({
                    path: "/",
                    query: {
                        price: "yes",
                    },
                });
            }
        } else if (!this.quoteAvailable) {
            if (isQueryPriceYes || isQueryPriceNo) {
                this.$router.push({
                    path: "/",
                });
            }
        }
    },
    methods: {
        handleQuote(quote) {
            this.quote = quote;
        },
        resizeHandler() {
            this.innerHeight = window.innerHeight;
            if (this.innerHeight !== null && this.innerHeight > 1200) {
                this.sizing = "lg"
            } else {
                this.sizing = "md"
            }
        },
        resetQuote() {
            this.quote = {};
        }
    }
};
</script>
