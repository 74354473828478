import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "../router";
import Vue from "vue";
import { InzuraLogger } from "../plugins";


function setupSentry() {
    const env = process.env.VUE_APP_ENVIRONMENT;
    if (!env.includes("local") || process.env.VUE_APP_SEND_TO_SENTRY_ALWAYS === "true") {
        // handle success
        Sentry.init({
            dsn: "https://fe1ac447e21d43fa9641c1894a1e8a04@o96068.ingest.sentry.io/4504014594965504",
            integrations: [
                new BrowserTracing({
                    routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                }),
            ],
            maxBreadcrumbs: 10,
            environment: env,
            release: process.env.VUE_APP_PORTAL_VERSION,
            tracesSampleRate: env.includes("local") ? 1.0 : 0.2,
            logErrors: env === "production" ? false : true,
            debug: env === "local" ? true : false,
            attachProps: true,
            attachStacktrace: true,
        });

        Vue.use(InzuraLogger);

        console.log(`Setup Sentry with environment = ${env}`);
    } else {
        console.log("Not setting up Sentry as running local without force on");
    }
}

export default {
    setupSentry
};
