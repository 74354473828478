/* jshint esversion: 6 */
import axios from "axios";
import Serialize from "serialize-javascript";
import router from "@/router";
import {
    getDateTimeInSecondsFromString
} from "@/js/DisplayUtils";
import {
    isString,
    isArray,
    isObject
} from "lodash";

// Setup common headers
const lang = navigator.language || navigator.userLanguage;
axios.defaults.headers.common["X-DISPLAY-LANGUAGE"] = lang;
axios.defaults.baseURL = "https://quote.zixty.com/";

export default {
    logError(message) {
        if (this.$logger) {
            this.$logger.error(message);
        } else {
            console.error(message);
        }
    },
    alertValidationErrorHandler(errorObject) {
        if (isString(errorObject)) {
            AlertUtils.alertError("Validation Error", errorObject);
        } else if (isArray(errorObject)) {
            for (let i = 0, len = errorObject.length; i < len; i++) {
                this.alertValidationErrorHandler(errorObject[i]);
            }
        } else if (isObject(errorObject)) {
            let keyList = Object.keys(errorObject);
            for (let i = 0, len = keyList.length; i < len; i++) {
                this.alertValidationErrorHandler(errorObject[keyList[i]]);
            }
        }
    },

    handleError(error, returnErrors=false) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            const isDownStatuses = [502, 503];

            if (error.response.status === 400) {
                console.log("400");
                this.alertValidationErrorHandler(error.response.data.error);
            }

        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            this.logError(error.request)

        } else {
            // Something happened in setting up the request that triggered an Error.
            this.logError(error.message);
        }

        // return generic error to the calling component
        return {
            error: "Failed to call API successfully",
            data: error.response.data,
            status: error.response.status
        };
    },


    shortenApiUrl(updateUrl) {
        return updateUrl.substring(updateUrl.indexOf("api/"));
    },

    async getData(path, config, returnErrors=false) {

        /**
         * Send HTTP GET request to API URL with desired path and set view state
         */
        // Tell app to start the in-process spinner

        let response;
        try {
            const customHeaders = {}
            if (config) {
                config["headers"] = customHeaders;
            }
            response = await axios.get(`${path}`, config ? config : {"headers": customHeaders});

            if (response && response.data) {
                return response;
            } else {
                throw "API response is undefined";
            }

        } catch (error) {
            return this.handleError(error, returnErrors);
        }
    },

    async postData(path, data, returnErrors=false) {
        /**
         * Send HTTP POST request to API URL with desired path and set view state
         */
        let response;
        try {
            response = await axios.post(`${path}`, data, {headers: {}});
            return response;
        } catch (error) {
            return this.handleError(error, returnErrors);
        }
    },

    async putData(path, data, returnErrors=false) {
        /**
         * Send HTTP PUT request to API URL with desired path and set view state
         */
        let response;
        try {
            response = await axios.put(`${path}`, data, {headers: {}});
            return response;
        } catch (error) {
            return this.handleError(error, returnErrors);
        }
    },

    async deleteData(path, returnErrors=false) {
        let response;
        try {
            response = await axios.delete(`${path}`);
            return response;
        } catch (error) {
            return this.handleError(error, returnErrors);
        }
    },

    serializeObjToEncodedQueryString(obj, prefix) {
        let str = [], p;
        for (p in obj) {
            if (obj.hasOwnProperty(p)) {
                var k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
                str.push((v !== null && typeof v === "object") ? serialize(v, k) : encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
        }
        return str.join("&");
    },

    async downloadFile(path, fileName, full_path = null) {
        let download_url = full_path != null ? full_path : `${path}`;
        axios({
            url: download_url,
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            responseType: "blob",
            transformRequest: [(data, headers) => {
                delete headers.common["X-DISPLAY-LANGUAGE"];
            }]
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
    },


    async getCustomer(customerId) {
        return await this.getData(`api/customer/${customerId}`);
    },

    async getAddresses(postcode) {
        return await this.getData(`v3/zixty/address/${postcode}/?expand=false`);
    },

    async getQuoteDocument(documentUrl) {
        return await axios({
            url: documentUrl,
            method: "GET",
            responseType: "blob",
        });
    },

    async postQuote(quoteData) {
        return await this.postData("v3/zixty/quickquote/", quoteData);
    },

};
